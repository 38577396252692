<template>
    <div>
        <base-header type="gradient-hyb-cyan" class="pb-6 pt-5 pt-md-8">
        </base-header>

        <div class="container-fluid mt--7">
            <div class="row">
                <div class="col">
                    <address-book-table
                      @paginate="handlePaginate"
                    ></address-book-table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AddressBookTable from './Tables/AddressBookTable'

export default {
  name: 'AddressBook',
  components: {
    AddressBookTable
  },
  mounted () {
    this.fetchSenders()
  },
  methods: {
    fetchSenders () {
      this.$store.dispatch('sender/fetchSenders')
    },
    handlePaginate (pageNum) {
      this.$store.dispatch('sender/setPageNum', pageNum)
      this.$store.dispatch('sender/fetchSenders')
    }
  }
};
</script>

<style></style>
