<template>
  <div class="card shadow">
    <div class="card-header border-0">
      <div class="row align-items-center">
        <div class="col">
          <h3 class="mb-0">
            Adressbuch
          </h3>
        </div>
        <div class="col text-right">
          <base-button type="primary" size="sm" @click="handleCreateSender">Absender erstellen</base-button>
        </div>
      </div>
    </div>

    <div class="table-responsive">
      <base-table v-if="senders.data" class="table align-items-center table-flush"
        thead-classes="thead-light" tbody-classes="list" :data="senders.data.docs">
        <template slot="columns">
          <th>Zeile 1</th>
          <th>Zeile 2</th>
          <th>Zeile 3</th>
          <th>Zeile 4</th>
          <th>Zeile 5</th>
          <th>Zeile 6</th>
          <th>Zeile 7</th>
          <th>Aktion</th>
        </template>

        <template slot-scope="{row}">
          <td v-for="idx in 7" :key="idx">
            {{ row.addressLines[idx - 1] }}
          </td>
          <td>
            <base-button type="success" size="sm" @click="handleEdit(row)">bearbeiten</base-button>
            <base-button type="danger" size="sm" @click="handleDelete(row)">löschen</base-button>
          </td>
        </template>
      </base-table>
    </div>
    <div class="card-footer d-flex justify-content-end">
      <base-pagination v-if="senders.data" :pageCount="senders.data.totalPages" :value="senders.data.page" @input="(val) => $emit('paginate', val)"></base-pagination>
    </div>
    <modal :show="senderModal.show">
      <card shadow type="secondary">
          <div slot="header" class="bg-white border-0">
              <div class="row align-items-center">
                  <div class="col-8">
                      <h3 class="mb-0">
                        {{ senderModal.isEdit ? 'Absender Bearbeiten' : 'Neuen Absender Erstellen' }}</h3>
                  </div>
                  <div class="col-4 text-right">
                    <button type="button"
                            class="close"
                            @click="senderModal.show = false"
                            data-dismiss="modal"
                            aria-label="Close">
                      <span>×</span>
                    </button>
                  </div>
              </div>
          </div>
          <template>
              <form ref="senderForm" @submit.prevent>
                  <div class="">
                      <div class="row">
                          <div class="col-lg-12">
                            <base-input alternative=""
                              label="Zeile 1"
                              input-classes="form-control-alternative"
                              v-model="model.zeile1"
                            />
                          </div>
                          <div class="col-lg-12">
                            <base-input alternative=""
                              label="Zeile 2"
                              input-classes="form-control-alternative"
                              v-model="model.zeile2"
                            />
                          </div>
                          <div class="col-lg-12">
                            <base-input alternative=""
                              label="Zeile 3"
                              input-classes="form-control-alternative"
                              v-model="model.zeile3"
                            />
                          </div>
                          <div class="col-lg-12">
                            <base-input alternative=""
                              label="Zeile 4"
                              input-classes="form-control-alternative"
                              v-model="model.zeile4"
                            />
                          </div>
                          <div class="col-lg-12">
                            <base-input alternative=""
                              label="Zeile 5"
                              input-classes="form-control-alternative"
                              v-model="model.zeile5"
                            />
                          </div>
                          <div class="col-lg-12">
                            <base-input alternative=""
                              label="Zeile 6"
                              input-classes="form-control-alternative"
                              v-model="model.zeile6"
                            />
                          </div>
                          <div class="col-lg-12">
                            <base-input alternative=""
                              label="Zeile 7"
                              input-classes="form-control-alternative"
                              v-model="model.zeile7"
                            />
                          </div>
                      </div>
                      <div class="row">
                        <div class="col text-right">
                          <base-button type="light" @click="senderModal.show = false">Abbrechen</base-button>
                          <base-button type="primary" :disabled="isSubmitting" @click="handleSubmit">Bestätigen</base-button>
                        </div>
                      </div>
                      <p v-if="errMsg" class="mb-0 mt-3 text-danger text-right text-sm">{{ errMsg }}</p>
                  </div>
              </form>
          </template>
      </card>
    </modal>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'address-book-table',
  data() {
    return {
      isSubmitting: false,
      errMsg: null,
      selectedSender: null,
      senderModal: {
        show: false,
        isEdit: false
      },
      model: {
        zeile1: null,
        zeile2: null,
        zeile3: null,
        zeile4: null,
        zeile5: null,
        zeile6: null,
        zeile7: null
      }
    }
  },
  computed: {
    ...mapState('sender', [
      'senders'
    ])
  },
  methods: {
    setSenderForm (sender = null) {
      if (sender) {
        const { addressLines } = sender
        this.model = {
          zeile1: addressLines[0] || null,
          zeile2: addressLines[1] || null,
          zeile3: addressLines[2] || null,
          zeile4: addressLines[3] || null,
          zeile5: addressLines[4] || null,
          zeile6: addressLines[5] || null,
          zeile7: addressLines[6] || null
        }
      } else {
        this.model = {
          zeile1: null,
          zeile2: null,
          zeile3: null,
          zeile4: null,
          zeile5: null,
          zeile6: null,
          zeile7: null
        }
      }
    },
    handleCreateSender () {
      this.errMsg = null
      this.senderModal.isEdit = false
      this.senderModal.show = true
    },
    handleEdit (row) {
      this.selectedSender = row
      this.setSenderForm (row)
      this.errMsg = null
      this.senderModal.isEdit = true
      this.senderModal.show = true
    },
    handleDelete (row) {
      this.$confirm(
        {
          message: `Möchten Sie den Absender löschen?`,
          button: {
            no: 'Nein',
            yes: 'Ja'
          },
          callback: confirm => {
            if (confirm) {
              this.$store.dispatch('sender/deleteSender', row._id)
                .then(() => {
                  this.$notify({type: 'success', message: 'Absender gelöscht'})
                })
                .catch(({error}) => {
                  this.$notify({type: 'danger', message: error.http_message})
                })
            }
          }
        }
      )
    },
    handleSubmit () {
      this.errMsg = null

      if (this.senderModal.isEdit) {
        // Editing sender
        if (!this.model.zeile1 && !this.model.zeile2 && !this.model.zeile3 && !this.model.zeile4 && !this.model.zeile5 && !this.model.zeile6 && !this.model.zeile7) {
          this.errMsg = 'Please fill in at least one of the sender fields'
          return
        }

        const addressLines = []
        if (this.model.zeile1) {
          addressLines.push(this.model.zeile1)
        }
        if (this.model.zeile2) {
          addressLines.push(this.model.zeile2)
        }
        if (this.model.zeile3) {
          addressLines.push(this.model.zeile3)
        }
        if (this.model.zeile4) {
          addressLines.push(this.model.zeile4)
        }
        if (this.model.zeile5) {
          addressLines.push(this.model.zeile5)
        }
        if (this.model.zeile6) {
          addressLines.push(this.model.zeile6)
        }
        if (this.model.zeile7) {
          addressLines.push(this.model.zeile7)
        }
        // Submit edit
        const payload = {
          senderId: this.selectedSender._id,
          body: {
            addressLines
          }
        }

        this.isSubmitting = true
        this.$store.dispatch('sender/updateSender', payload)
          .then((data) => {
            this.$store.commit('sender/UPDATE_SENDER', data)
            this.$notify({type: 'success', message: 'Der Sender wurde aktualisiert'})
            this.senderModal.show = false
            this.selectedSender = null
            this.setSenderForm()
          })
          .catch((error) => {
            this.$notify({type: 'danger', message: error?.response?.data?.error?.description || 'Fehler beim Versuch, den Absender zu aktualisieren' })
          })
          .finally(() => {
            this.isSubmitting = false
          })
      }
      else {
        // Creating new sender
        if (!this.model.zeile1 && !this.model.zeile2 && !this.model.zeile3 && !this.model.zeile4 && !this.model.zeile5 && !this.model.zeile6 && !this.model.zeile7) {
          this.errMsg = 'Please fill in at least one of the sender fields'
          return
        }

        const addressLines = []
        if (this.model.zeile1) {
          addressLines.push(this.model.zeile1)
        }
        if (this.model.zeile2) {
          addressLines.push(this.model.zeile2)
        }
        if (this.model.zeile3) {
          addressLines.push(this.model.zeile3)
        }
        if (this.model.zeile4) {
          addressLines.push(this.model.zeile4)
        }
        if (this.model.zeile5) {
          addressLines.push(this.model.zeile5)
        }
        if (this.model.zeile6) {
          addressLines.push(this.model.zeile6)
        }
        if (this.model.zeile7) {
          addressLines.push(this.model.zeile7)
        }

        // Submit create
        const payload = {
          addressLines
        }
        this.isSubmitting = true
        this.$store.dispatch('sender/createSender', payload)
          .then(() => {
            this.$notify({type: 'success', message: 'Neuer Absender wurde erstellt'})
            this.setSenderForm()
            this.senderModal.show = false
          })
          .catch((error) => {
            this.$notify({type: 'danger', message: error?.response?.data?.error?.description || 'Fehler beim Versuch, einen Absender zu erstellen' })
          })
          .finally(() => {
            this.isSubmitting = false
          })
      }
    }
  }
}
</script>

<style>
</style>
